<template>
  <div class="app-container">
    <!-- 标题 -->
    <div class="title_leftm">
      <div style="float: left;">
        <span class="span_title">{{ $t('m.navbar.center_dynamic') }}</span>
        <span v-if="this.$language === 'cn'" class="span_sub_title"> / News</span>
      </div>
    </div>
    <!-- 内容 -->
    <div v-loading="loading" style="min-height: 350px;">
      <div v-if="obj">
        <div style="text-align: center;">
          <h3 style="padding: 0 20px;">{{ obj.title }}</h3>
          <span>{{ $t('m.hezuo.release_time') }}</span>
          <span>{{ obj.time }}</span>
        </div>
        <div class="isre_conts" style="margin-top: 40px;">
          <!--后台数据-->
          <div v-html="obj.body" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//
import { newsDetails } from '@/api/cceec/news'
export default {
  name: 'DynamicDetails',
  data() {
    return {
      loading: true,
      id: null,
      obj: null
    }
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getDetails()
    }
  },
  methods: {
    getDetails() {
      this.loading = true
      const data = {
        languageType: this.$languageType,
        preview: this.$route.query.preview
      }
      newsDetails(this.id, data).then(res => {
        this.loading = false
        console.log(res)
        this.obj = res.data
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .title_leftm {
    margin: 20px auto 0;
    width: 1200px;
    height: 40px;
    white-space: nowrap;
    border-bottom: 1px #dddbdb dashed;
    .span_title {
      font-size: 18px;
      color: #2890eb;
    }
    .span_sub_title {
      color: #999;
      font-size: 14px;
      font-family: arial;
    }
  }
  h3 {
    font-weight: lighter;
    margin-bottom: 30px;
    font-size: 20px !important;
  }
  .isre_conts {
    padding: 0 50px;
    width: 1100px;
    font-size: 14px;
    line-height: 30px;
    word-break: normal;
    /*word-break: break-all;*/
    word-wrap: break-word;
  }
</style>
